import { ecorEnv } from '@/../environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DelegateService {

  constructor(private httpClient: HttpClient) { }


  verifyDelegationRequest(emailReferencedId: string) {
    const url = ecorEnv.apiEndpoint + '/delegates/requests/' + emailReferencedId;
    return this.httpClient.get<any>(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('DelegateService', 'client side error:', error.error.message);
    } else {
      return throwError(error);
    }
    return throwError('Delegation error');
  }
}
