import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EcorrBrowserCheckComponent } from './ecorr-browser-check.component';
import '@ecorr/components/main';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        EcorrBrowserCheckComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        EcorrBrowserCheckComponent
    ],
    schemas: [ 
        CUSTOM_ELEMENTS_SCHEMA 
    ] 
})
export class EcorrBrowserCheckModule { }
