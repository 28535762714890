import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyViewsInstanceService } from '../survey-views-instance-base/survey-views-instance.service';

@Component({
  selector: 'app-survey-report-now',
  templateUrl: './survey-report-now.component.html',
  styleUrls: ['./survey-report-now.component.scss'],
  providers: [SurveyViewsInstanceService]
})
export class SurveyReportNowComponent implements OnInit {
  loading?: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private surveyViewsInstanceService: SurveyViewsInstanceService
  ) { }

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.surveyViewsInstanceService.accessCenturionV2WithParams(queryParams).subscribe(
        (data) => {
            this.loading = false;
            if (data.jwt) {
                console.log(`${queryParams.centurionUrl}?census_auth=${data.jwt}`);
                window.location.href = `${queryParams.centurionUrl}?census_auth=${data.jwt}`;
            }
        },
        (error: any) => {
            this.loading = false;
        }
    );
  }

}
