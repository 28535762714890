import { ecorEnv } from '@/../environments/environment';
import { AppService } from '@/app.service';
import { PortalUser } from '@/common/components/ecorr-store/portal-user/portal-user';
import { portalUserSelector } from '@/common/components/ecorr-store/portal-user/portal-user.selector';
import { PortalUserState } from '@/common/components/ecorr-store/portal-user/portal-user.state';
import { ParadataEventEnum, ParadataEventService } from '@/common/paradata-event/paradata-event.service';
import { AppSettings, AppSettingsService } from '@/common/services/app-settings.service';
import { EcorrUtilsService } from '@/common/utils/ecorr-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-ecorr-nav',
    templateUrl: './ecorr-nav.component.html'
})
export class EcorrNavComponent implements OnInit, OnDestroy {
    
    event$: Subscription;
    user$: Subscription;
    portalUser: Observable<PortalUser>;
    settings: AppSettings = { 
        pegaIntegration: true
    };

    constructor(public router: Router,
            private store: Store<PortalUserState>,
            private appService: AppService,
            private appSettingsService: AppSettingsService,
            private paradataEventService: ParadataEventService,
            private ecorrUtilsService: EcorrUtilsService) {
        this.getPortalUser();
    }

    ngOnInit() {
        setTimeout(() => {
            this.appService.loading = false;
        }, 3000);
    }

    getPortalUser() {
        let settingsRetrieved = false;
        this.portalUser = this.store.select(portalUserSelector);
        this.user$ = this.portalUser.subscribe( observer => {
            //PortalUser must exist before accessing secure endpoints
            if (!settingsRetrieved && observer && observer.userAccountId > 0) {
                this.getAppSettings();
                settingsRetrieved = true;
            }
        });
    }

    getAppSettings() {
        let settingslessRoutes = [ '/portal', '/faq' ];

        if ( settingslessRoutes.indexOf( window.location.pathname ) === -1 ) {
            this.appSettingsService.getAppSettings().subscribe( settings => {
                if ( typeof settings === 'string' ) {
                    // Manually retyping to string then removing tainting and reparsing
                    this.settings = JSON.parse( JSON.stringify( settings ).slice( 8, -1 ).replace( /\\/g, '' ) );
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.event$) {
            this.event$.unsubscribe();
        }
        if (this.user$) {
            this.user$.unsubscribe();
        }
    }

    logout() {
        this.ecorrUtilsService.logout();
    }

    sendMessage() {
        this.paradataEventService.add(ParadataEventEnum.Message);

        this.router.navigate(['/submit-case'], {
            queryParams:
            {
                surveyInstanceId: -102030405
            }
        });
    }


    public showAccountSettings(): void {
        // console.log('[showAccountSetting] clicked');
        this.paradataEventService.add(ParadataEventEnum.ManageAccount);
        if (ecorEnv.cpassEnabled) {
            window.location.href = ecorEnv.accountSettingsUrl;
        }
    }
}
