<div class="uscb-layout-row uscb-layout-column-md uscb-margin-T-10">
    <div class="uscb-layout-column-md">
        <h1>Respondent Portal FAQ</h1>
    </div>
</div>

<hr class="uscb-width-100 uscb-hr-title uscb-bg-accent uscb-margin-T-0 uscb-margin-B-20 uscb-margin-LR-0" role="presentation" />

<div class="uscb-layout-row uscb-layout-column-md">
    <div class="uscb-width-100 uscb-layout-column-md">
        <div *ngFor="let subtopic of faqService.subtopicList" class="uscb-margin-B-30">
            <h2 tabindex="0">{{subtopic.replace( 'FAQ ', '' ) }}</h2>
            
            <div class="uscb-accordion">
                <div *ngFor="let faqItem of faqList[subtopic]; let i = index" (click)="toggleAccordion(i, subtopic, $event)" tabindex="0" (keyup.enter)="toggleAccordion(i, subtopic, $event)" aria-expanded="false">
                    <div class="uscb-layout-row uscb-layout-align-start-center uscb-accordion-header uscb-accordion_panel_head">
                        <span class="uscb-chevron-icon-img o-angle-down-1" alt="" role="presentation"></span> 
                        <span class="uscb-flex uscb-margin-T-0 uscb-margin-L-45 uscb-title-3">
                            {{faqItem.label}}
                        </span>
                    </div>
                    
                    <div class="uscb-accordion_panel uscb-accordion-show-panel">
                        <p class="uscb-font-sm uscb-margin-0" [innerHTML]="faqItem.content"> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-ecorr-browser-check [display]="browserCheckDisplay" [addedToPage]="openedAccordion"></app-ecorr-browser-check>