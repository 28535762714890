class EcorEnv {

    private localHost = 'localhost';
    private devHost = 'ecorr.dev.it.census.gov';
    private testHost = 'ecorr.test.it.census.gov';
    private uaHost = 'ecorr.ua.it.census.gov';
    private stageHost = 'ecorr.stage.it.census.gov';
    private trainHost = 'ecorr.train.it.census.gov';
    private iteHost = 'ecorr.ite.it.census.gov';
    private prodHost = 'portal.census.gov';

    private aiesDevHost = 'ecorr-aies.dev.it.census.gov';
    private aiesTestHost = 'ecorr-aies.test.it.census.gov';
    private aiesITEHost = 'ecorr-aies.ite.it.census.gov';
    private aiesUAHost = 'ecorr-aies.ua.it.census.gov';

    cpassEnabled: boolean;

    apiEndpoint: string;
    registerUrl: string;
    logoutUrl: string;
    timeoutParams: string;
    pegaHostUrl: string; /** Not in use? */
    faqUrl: string;
    faqPageLink: string;
    accountSettingsUrl: string;
    env: string;

    get pageNeedsAuthentication() {
        return true;
    }

    get production() {
        return true;
    }

    constructor() {
        this.cpassEnabled = true;

        switch (location.hostname) {
            case this.prodHost:
                this.apiEndpoint = 'https://' + this.prodHost + '/ecorr/api';
                this.registerUrl = 'https://registration.tco.census.gov/myreg';
                this.logoutUrl = 'https://' + this.prodHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.prodHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.it.census.gov';
                this.faqUrl = 'https://ask.census.gov/';
                this.faqPageLink = 'https://' + this.prodHost + '/faq';
                this.accountSettingsUrl = 'https://registration.tco.census.gov/myreg/up';
                this.env = 'prod';
                break;
            case this.testHost:
                this.apiEndpoint = 'https://' + this.testHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.testHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.testHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.test.it.census.gov';
                this.faqUrl = 'https://ask.test.census.gov/';
                this.faqPageLink = 'https://' + this.testHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'test';
                break;
            case this.devHost:
                this.apiEndpoint = 'https://' + this.devHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myreg';
                this.logoutUrl = 'https://' + this.devHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.devHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.dev.it.census.gov';
                this.faqUrl = 'https://ask.dev.census.gov/';
                this.faqPageLink = 'https://' + this.devHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myreg/up';
                this.env = 'dev';
                break;
            case this.uaHost:
                this.apiEndpoint = 'https://' + this.uaHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.uaHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.uaHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.ua.it.census.gov';
                this.faqUrl = 'https://ask.ua.census.gov/';
                this.faqPageLink = 'https://' + this.uaHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'ua';
                break;
            case this.stageHost:
                this.apiEndpoint = 'https://' + this.stageHost + '/ecorr/api';
                this.registerUrl = 'https://registration.tco.census.gov/myregstage';
                this.logoutUrl = 'https://' + this.stageHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.stageHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.stage.it.census.gov';
                this.faqUrl = 'https://ask.stage.census.gov/';
                this.faqPageLink = 'https://' + this.stageHost + '/faq';
                this.accountSettingsUrl = 'https://registration.tco.census.gov/myregstage/up';
                this.env = 'stage';
                break;
            case this.trainHost:
                this.apiEndpoint = 'https://' + this.trainHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.trainHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.trainHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.train.it.census.gov';
                this.faqUrl = 'https://ask.train.census.gov/';
                this.faqPageLink = 'https://' + this.trainHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'train';
                break;

            case this.iteHost:
                this.apiEndpoint = 'https://' + this.iteHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.iteHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.iteHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.ite.it.census.gov';
                this.faqUrl = 'https://ask.ite.census.gov/';
                this.faqPageLink = 'https://' + this.iteHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'train';
                break;

            case this.localHost:
                this.cpassEnabled = false;

                //this.apiEndpoint = 'http://' + this.localHost + ':8090/api';
                this.apiEndpoint = 'https://ecorr.test.it.census.gov/ecorr/api';

                this.logoutUrl = 'http://' + this.localHost + ':8090/logout';

                /** Lines with port number 7001 are reserved for laptop weblogic */
                // this.apiEndpoint = 'http://' + this.localHost + ':7001/ecorr/api'; 
                // this.logoutUrl = 'http://' + this.localHost + ':7001/ecorr/logout';

                this.registerUrl = 'http://' + this.localHost + ':4200/register';
                this.timeoutParams = '?return=https://' + this.localHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm.dev.it.census.gov';
                this.faqUrl = 'https://ecorrcrm.dev.it.census.gov/prweb/PRServletCustom';
                this.faqPageLink = 'http://' + this.localHost + ':4200/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myreg/up';
                this.env = 'local';
                break;
            case this.aiesUAHost: //similar to testHost
                this.apiEndpoint = 'https://' + this.aiesUAHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.aiesUAHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.aiesUAHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm-aies.ua.econ.census.gov';
                this.faqUrl = 'https://ecorrcrm-aies.ua.econ.census.gov/prweb/PRServletCustom';
                this.faqPageLink = 'https://' + this.aiesUAHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'ua';
                break;
            case this.aiesITEHost: //similar to testHost
                this.apiEndpoint = 'https://' + this.aiesITEHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.aiesITEHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.aiesITEHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm-aies.ite.econ.census.gov';
                this.faqUrl = 'https://ecorrcrm-aies.ite.econ.census.gov/prweb/PRServletCustom';
                this.faqPageLink = 'https://' + this.aiesITEHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'test';
                break;
            case this.aiesTestHost: //similar to testHost
                this.apiEndpoint = 'https://' + this.aiesTestHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myregtest';
                this.logoutUrl = 'https://' + this.aiesTestHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.aiesTestHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm-aies.test.econ.census.gov';
                this.faqUrl = 'https://ecorrcrm-aies.test.econ.census.gov/prweb/PRServletCustom';
                this.faqPageLink = 'https://' + this.aiesTestHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myregtest/up';
                this.env = 'test';
                break;
            case this.aiesDevHost: //similar to devHost
                this.apiEndpoint = 'https://' + this.aiesDevHost + '/ecorr/api';
                this.registerUrl = 'https://registration.dev.tco.census.gov/myreg';
                this.logoutUrl = 'https://' + this.aiesDevHost + '/Shibboleth.sso/Logout';
                this.timeoutParams = '?return=https://' + this.aiesDevHost + '/portal?timeout=true';
                this.pegaHostUrl = 'https://ecorrcrm-aies.dev.econ.census.gov';
                this.faqUrl = 'https://ecorrcrm-aies.dev.econ.census.gov/prweb/PRServletCustom';
                this.faqPageLink = 'https://' + this.aiesDevHost + '/faq';
                this.accountSettingsUrl = 'https://registration.dev.tco.census.gov/myreg/up';
                this.env = 'dev';
                break;
            default:
                break;
        }
    }

    isProd() {
        return location.hostname === this.prodHost;
    }
}

export const ecorEnv = new EcorEnv();
