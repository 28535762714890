<section class="usa-banner" aria-label="Official government website">
    <div class="usa-accordion">
        <header class="usa-banner__header">
            <div class="usa-banner__inner">
                <div class="grid-col-auto">
                    <img class="usa-banner__header-flag" src="./assets/img/us_flag_small.png" alt="U.S. flag" />
                </div>
                <div class="grid-col-fill tablet:grid-col-auto">
                    <p class="usa-banner__header-text">
                        {{'usaBanner.headerText' | translate}}
                    </p>
                    <p class="usa-banner__header-action" aria-hidden="true">
                        {{'usaBanner.headerActionText' | translate}}
                    </p>
                </div>
                <button class="usa-accordion__button usa-banner__button" aria-expanded="false" aria-controls="gov-banner-default">
                    <span class="usa-banner__button-text">
                        {{'usaBanner.headerActionText' | translate}}
                    </span>
                </button>
            </div>
        </header>
        <div class="usa-banner__content usa-accordion__content" id="gov-banner-default" hidden>
            <div class="grid-row grid-gap-lg">
                <div class="usa-banner__guidance tablet:grid-col-6">
                    <img class="usa-banner__icon usa-media-block__img" src="./assets/img/icon-dot-gov.svg" role="img"
                        alt="" aria-hidden="true" />
                    <div class="usa-media-block__body">
                        <p>
                            <strong> {{'usaBanner.govSectionTitle' | translate}} </strong>
                            <br />
                            <span [innerHTML]="'usaBanner.govSectionBodyHtml' | translate">
                            </span>
                        </p>
                    </div>
                </div>
                <div class="usa-banner__guidance tablet:grid-col-6">
                    <img class="usa-banner__icon usa-media-block__img" src="./assets/img/icon-https.svg" role="img"
                        alt="" aria-hidden="true" />
                    <div class="usa-media-block__body">
                        <p>
                            <strong> {{'usaBanner.secureSectionTitle' | translate}} </strong>
                            <br />
                            <span [innerHTML]="'usaBanner.secureSectionBodyHtml' | translate: {lockImgHtml: lockImgHtml}"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>