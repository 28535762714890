import { Component, HostListener, OnInit, OnDestroy, Inject } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IdleTimeoutDialogData } from './idle-timeout-dialog-data';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-idle-timeout-dialog',
    templateUrl: './idle-timeout-dialog.component.html'
})
export class IdleTimeoutDialogComponent implements OnInit, OnDestroy {
    countdown = this.idle.getTimeout() * 1000;
    idleTimeoutConfirm$: Subscription;

    constructor(private idle: Idle, @Inject(MAT_DIALOG_DATA) public idleTimeoutDialogData: IdleTimeoutDialogData,
        private mdDialogRef: MatDialogRef<IdleTimeoutDialogComponent>) { }

    ngOnInit() {
        this.idleTimeoutConfirm$ =
            this.idle.onTimeoutWarning.subscribe((countdowntime) => {
                this.countdown = countdowntime * 1000;
            });
    }


    ngOnDestroy() {
        if (this.idleTimeoutConfirm$) {
            this.idleTimeoutConfirm$.unsubscribe();
        }
    }


    close(value: any) {
        this.mdDialogRef.close(value);
    }

    resetTimer() {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.watch();
        this.close('continue');
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.close('ESC keydown');
    }
}
