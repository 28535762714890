import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  screenWidth: number;
  screenHeight: number;
  screenSize: string;

  constructor() {
    this.screenHeight = window.innerWidth;
    this.screenWidth = window.innerHeight;
    this.screenSize = '';
    this.setScreenSizeOnResize();
  }

  private setScreenSizeOnResize(): void {
    window.addEventListener("resize", () => {
      this.screenHeight = window.innerWidth;
      this.screenWidth = window.innerHeight;
    });
  }

  getScreenWidth(): number {
    return this.screenWidth
  }

  getScreenHeight(): number {
    return this.screenHeight;
  }
  getScreeSize(): string {
    return `${this.screenHeight}X${this.screenWidth}`;
  }

}
