<section>
    <div class="uscb-layout-row uscb-layout-align-space-between uscb-bg-primary uscb-color-white uscb-padding-15">
        <div class="uscb-title-2">
            Warning - Session Expiring
        </div>
    </div>
    <div class="uscb-layout-column uscb-layout-align-center uscb-layout-align-vert-center uscb-padding-10">
        <div class="center-text xxlg-text uscb-margin-TB-8 uscb-margin-LR-0">{{countdown | date: 'mm:ss'}}</div>
        <div class="center-text uscb-width-100">Your session is set to expire in {{countdown  | date: 'mm:ss'}}
            minutes and seconds.
            Please select "Continue" to extend your session or you will be signed out</div>
    </div>
    <br>
    <div class="uscb-layout-row uscb-layout-align-center uscb-layout-align-vert-center">
        <button class="uscb-primary-button" (click)="resetTimer()" (keyup.enter)="resetTimer()" tabindex="0">
            Continue
        </button>
    </div>
</section>
