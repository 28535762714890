<section [class]="headerFaqDialogData.dialogClass">
    <div class="uscb-layout-row uscb-bg-primary uscb-color-white">
        <span class="vcenter uscb-flex uscb-padding-TB-0 uscb-padding-LR-8">
            {{headerFaqDialogData.title}}
        </span>
        <span class="vcenter">
            <mat-icon (click)="close(true)" (keyup.enter)="close(true)" tabindex="0" aria-hidden="false">close</mat-icon>
        </span>
    </div>

    <mat-dialog-content>
        <div class="uscb-layout-row uscb-layout-column-md">
            <div class="uscb-width-100 uscb-layout-column-md">
                <div class="uscb-accordion">
                    <div *ngFor="let faqItem of faqList; let i = index" (click)="toggleAccordion(i, $event)" tabindex="0" (keyup.enter)="toggleAccordion(i, $event)" aria-expanded="false">
                        <div class="uscb-layout-row uscb-layout-align-start-center uscb-accordion-header uscb-accordion_panel_head">
                            <span class="uscb-chevron-icon-img o-add-1" alt="" role="presentation"></span> 
                            <span class="uscb-flex uscb-margin-T-0 uscb-margin-L-45 uscb-title-3 uscb-accordion-header-content" [innerHTML]="faqItem.question"></span>
                        </div>
                        
                        <div class="uscb-accordion_panel uscb-accordion-show-panel">
                            <p class="uscb-font-sm uscb-margin-0" [innerHTML]="faqItem.answer"> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="uscb-layout-row-gt-md uscb-layout-column-md uscb-layout-column-sm uscb-layout-align-end-end">
        <button type="button" class="uscb-primary-button" matDialogClose tabindex="0">
            {{headerFaqDialogData.closeButtonLabel}}
        </button>
    </mat-dialog-actions>
</section>