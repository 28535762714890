import { ecorEnv } from '@/../environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { StoreModule } from '@ngrx/store';
import { NgxMaskModule } from 'ngx-mask';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheInterceptor } from './common/interceptors/cache.interceptor';
import { EcorrBannerComponent } from './common/components/ecorr-banner/ecorr-banner.component';
import { EcorrFooterComponent } from './common/components/ecorr-footer/ecorr-footer.component';
import { EcorrHeaderComponent } from './common/components/ecorr-header/ecorr-header.component';
import { EcorrUsaBannerComponent } from './common/components/ecorr-usa-banner/ecorr-usa-banner.component';
import { EcorrNavComponent } from './common/components/ecorr-nav/ecorr-nav.component';
import { EcorrNavModule } from './common/components/ecorr-nav/ecorr-nav.module';
import { portalUserReducer } from './common/components/ecorr-store/portal-user/portal-user.reducer';
import { PageNotFoundComponent } from './page-not-found.component';
import { IdleTimeoutDialogModule } from './support/dialogs/idle-timeout-dialog/idle-timeout-dialog.module';
import { FaqComponent } from './support/faq/faq.component';
import { AppStylePaginatorDirective } from './surveys/directive/app-style-paginator.directive';
import { ServiceModule } from './common/services/services.module';
import { EcorrBrowserCheckModule } from './common/components/ecorr-browser-check/ecorr-browser-check.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderFaqDialogModule } from './landing/dialogs/header-faq-dialog/header-faq-dialog.module';
import { EcorrSC24HeaderComponent } from './landing/components/ecorr-sc24-header/ecorr-sc24-header.component';
import { EcorrSC24FooterComponent } from './landing/components/ecorr-sc24-footer/ecorr-sc24-footer.component';
import { HelpDialogModule } from './landing/dialogs/help-dialog/help-dialog.module';
import { SurveyReportNowComponent } from './surveys/components/survey-report-now/survey-report-now.component';
import { NotFoundComponent } from './not-found/not-found.component';

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        EcorrHeaderComponent,
        EcorrFooterComponent,
        EcorrSC24HeaderComponent,
        EcorrSC24FooterComponent,
        PageNotFoundComponent,
        EcorrNavComponent,
        EcorrBannerComponent,
        EcorrUsaBannerComponent,
        FaqComponent,
        AppStylePaginatorDirective,
        SurveyReportNowComponent,
        NotFoundComponent
        ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        FormsModule,
        NgxWebstorageModule.forRoot(),
        MatToolbarModule,
        EcorrNavModule,
        EcorrBrowserCheckModule,
        HeaderFaqDialogModule,
        HelpDialogModule,
        IdleTimeoutDialogModule,
        MatIconModule,
        NgxMaskModule.forRoot({ validation: true }),
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatSortModule,
        MatButtonModule,
        MatRadioModule,
        StoreModule.forRoot({ portalUserState: portalUserReducer }),
        AppRoutingModule,
        ServiceModule,
        NgIdleKeepaliveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoaderFactory,
              deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '.' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'en' }
    ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ] 
})
export class AppModule { }
