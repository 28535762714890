<ng-container *ngIf="!isSC24; else sc24">
    <section class="app ecorr-env-{{ env }}">
        <app-ecorr-header></app-ecorr-header>
        <app-ecorr-nav></app-ecorr-nav>

        <app-ecorr-banner></app-ecorr-banner>

        <div class="ecorr-main-content" id="main" tabindex="0">
            <router-outlet></router-outlet>
        </div>

        <div class="uscb-hide-gt-md uscb-margin-T-50"></div>

        <app-ecorr-footer></app-ecorr-footer>
    </section>
</ng-container>
<ng-template #sc24>
    <section class="app sc-bg ecorr-env-{{ env }}">
        <app-ecorr-sc24-header></app-ecorr-sc24-header>

        <div class="ecorr-main-content sc-main-content" id="main" tabindex="0">
            <router-outlet></router-outlet>
        </div>

        <div class="uscb-hide-gt-md uscb-margin-T-50"></div>

        <app-ecorr-sc24-footer></app-ecorr-sc24-footer>
    </section>
</ng-template>