import { ecorEnv } from '@/../environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface ParadataEvent {
    paradataEventTypeId: ParadataEventEnum
    surveyId: number;
    eventDtm: Date;
}

export const ParadataEventParam = 'paradataEventJson';

export enum ParadataEventEnum {
    NumberofSSRssubmittedbySurvey = 1,
    ReportNow = 2,
    ExportCSV = 3,
    ExportExcel = 4,
    UsedAuthenticationCode = 5,
    InvalidAuthenticationCode = 6,
    ExpiredAuthenticationCode = 7,
    NumberofSSRssubmittedwithattachment = 8,
    RemoveDelegates = 9,
    ResentRequest = 10,
    NumberofCaseswithcomments = 11,
    Caseswithattachmentsfromcomments = 12,
    FilingStatus = 13,
    HowDoI = 14,
    CancelMessage = 15,
    SubmitMessage = 16,
    ActiveTabClick = 17,
    SurveyDashboardHistoryTab = 18,
    MessageStatusResolved = 19,
    MessageStatusActive = 20,
    RequesttoOpen = 21,
    AddAuthenticationCode = 22,
    CancelAuthenticationCodeRequest = 23,
    BeginbuttonforAuthenticationCode = 24,
    GridView = 25,
    ListView = 26,
    ManageAccount = 27,
    SignOut = 28,
    CensusBreadcrumbs = 29,
    Options = 30,
    Information = 31,
    MoreInformation = 32,
    FAQs = 33,
    Message = 34,
    ShareSurveyAccess = 35,
    TimeExtension = 36,
    ShareSurveySubmitSuccess = 37,
    ShareSurveySubmitFail = 38,
    CurrentDelegates = 39,
    Enterednotestodelegate = 40,
    COSInformationClick = 41,
    ASMInformation = 42,
    CancelShareSurveyAccessRequest1 = 43,
    CancelShareSurveyAccessRequest2 = 44,
    PostbuttontoReopen = 45,
    ASMFAQs = 46,
    COSFAQs = 47,
    ConfirmShareSurveyAccess = 48,
    Watchvideoforthename = 49,
    Canister = 50,
    UnauthorizedCode=51
}

@Injectable({
    providedIn: 'root'
})
export class ParadataEventService {
    constructor(private httpClient: HttpClient) { }

    url = ecorEnv.apiEndpoint + '/secure/sessions';

    events: ParadataEvent[] = [];

    add(paradataEventEnum: ParadataEventEnum, surveyId: number = null): void {
        this.events.push({
            paradataEventTypeId: paradataEventEnum,
            surveyId: surveyId,
            eventDtm: new Date()
        });
    }

    getAndClear(): string {
        if (this.events.length === 0) {
            return null;
        }

        const ret = JSON.stringify(this.events);
        this.events = [];
        return ret;
    }

    flushParadataevents() {
        if (this.events.length > 0) {
            const params = new HttpParams()
                .set(ParadataEventParam, this.getAndClear());

            this.httpClient.post<any>(this.url, params)
                .pipe(
                    catchError(this.handleError)
                ).subscribe();
        }
    }

    private handleError(error: HttpErrorResponse) {
        console.error('surveys', 'error', error);
        if (error.error instanceof ErrorEvent) {
            console.error('surveys', 'client side error:', error.error.message);
        } else {
            return throwError('Error-111111');
        }
        return throwError('Error-222222');
    }
}
