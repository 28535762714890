import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HelpDialogData } from './help-dialog-data';
import { HelpDialogComponent } from './help-dialog.component';

@Injectable()
export class HelpDialogService {
    constructor(private dialog: MatDialog) { }
    matDialogRef: MatDialogRef<HelpDialogComponent>;

    open(notificationCalendarDialogData: HelpDialogData) {
        const matDialogConfig = new MatDialogConfig();
        matDialogConfig.panelClass = 'myapp-dialog';
        matDialogConfig.width = '50%';
        matDialogConfig.data = notificationCalendarDialogData;
        matDialogConfig.disableClose = false;
        matDialogConfig.hasBackdrop = true;
        this.matDialogRef = this.dialog.open(HelpDialogComponent, matDialogConfig);
    }

}