import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ecorr-header',
    templateUrl: './ecorr-header.component.html'
})
export class EcorrHeaderComponent implements OnInit {

    currentUrl: any = ''
    loading = false;

    constructor() { }

    ngOnInit() {
    }

    focusOnMain() {
        const mainContent: HTMLElement = document.querySelector('#main');
        if (mainContent) {
            mainContent.focus();
        }
    }

    getUserInitials() {
        return 'CNMP';
    }

    getFullName() { }

    getEmail() { }

}
