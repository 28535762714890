import { PortalUserState } from '@/common/components/ecorr-store/portal-user/portal-user.state';
import { SurveyCard } from '@/surveys/states/surveys/survey-card';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EcorrBanner } from './ecorr-banner';
import { EcorrBannerService } from './ecorr-banner.service';
import { EcorrVisibleBanner } from './ecorr-visible-banner';
import { PortalUser } from './../ecorr-store/portal-user/portal-user';
import { portalUserSelector } from '@/common/components/ecorr-store/portal-user/portal-user.selector';

@Component({
    selector: 'app-ecorr-banner',
    templateUrl: './ecorr-banner.component.html'
})
export class EcorrBannerComponent implements OnInit, OnDestroy {
    ecorrBanner$: Subscription;
    sub$: Subscription;
    user$: Subscription;
    error = '';

    icons = {
        info: 'fa-exclamation-circle',
        warning: 'fa-exclamation-triangle',
        error: 'fa-ban'
    };

    colors = {
        info: '#F1F3F5',
        warning: '#FFFFEE',
        error: '#FFDDDD'
    }

    route: string;
    previousRoute: string = '';
    activeSurveyCards: SurveyCard[];
    loading = false;
    bannerSet: Set<EcorrVisibleBanner>;
    visibleBanners: EcorrVisibleBanner[];

    constructor(private store: Store<PortalUserState>,
        public ecorrBannerService: EcorrBannerService,
        private router: Router) {
        /* survey card based visibility */
        this.getPortalUser();
        
        this.sub$ = this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.route = e.url;
                this.resetVisibility();
            }
        });
    }

    getPortalUser() {
        let user = this.store.select(portalUserSelector);
        this.user$ = user.subscribe( userObserver => {
            //User must exist before accessing survey cards
            if (userObserver && userObserver.userAccountId > 0) {
                this.activeSurveyCards = userObserver ? userObserver.activeSurveyCards : null;
                this.resetVisibility();
            }
        });
    }

    ngOnInit() { }

    resetVisibility() {
        if (this.loading) {
            return;
        } else {
            this.loading = true;
        }

        this.bannerSet = new Set<EcorrVisibleBanner>();
        this.ecorrBanner$ = this.ecorrBannerService.getActiveBanners('ecorr').subscribe(
            (data: EcorrBanner[]) => {
                data.forEach(item => {
                    const visibleBanner: EcorrVisibleBanner = new EcorrVisibleBanner();
                    visibleBanner.trustedContent = item.messageContent;
                    visibleBanner.icon = this.icons[item.bannerType.type];
                    visibleBanner.displayLocation = item.displayLocation;
                    visibleBanner.color = this.colors[item.bannerType.type];
                    visibleBanner.show = this.determineVisibilty(item);
                    visibleBanner.closable = item.closeable;
                    visibleBanner.type = item.bannerType.type;
                    visibleBanner.id = item.webappBannerId;

                    this.addToSet(this.bannerSet, visibleBanner);
                });
                this.visibleBanners = [...this.bannerSet].sort((a, z) => {
                    return a.type.localeCompare(z.type, 'en', { sensitivity: 'base' });
                });
                this.loading = false;
            },
            error => {
                this.loading = false;
                this.error = error;
                console.error(this.error);
            }
        );
    }

    isInSet(set: Set<EcorrVisibleBanner>, banner: EcorrVisibleBanner) {
        let found = false;
        set.forEach(item => {
            if (item.id === banner.id) {
                found = true;
            }
        });
        return found;
    }

    addToSet(set: Set<EcorrVisibleBanner>, banner: EcorrVisibleBanner) {
        if (!this.isInSet(set, banner)) {
            set.add(banner);
        }
    }

    deleteFromSet(set: Set<EcorrVisibleBanner>, banner: EcorrVisibleBanner) {
        set.forEach(item => {
            if (item.id === banner.id) {
                set.delete(item);
            }
        });
    }

    determineVisibilty(banner: EcorrBanner): boolean {
        if (!this.route) {
            return false;
        }

        if (this.route.includes('faq')) {
            return false;
        }

        const path = banner.displayLocation?.toLocaleLowerCase();

        /* survey card based visibility */ 
        let display = false;
        if (banner.surveyCategory && this.activeSurveyCards && this.activeSurveyCards.length > 0) {
            this.activeSurveyCards.forEach(survey => {
                if (path === 'all' || path === 'internal') {
                    if (banner.surveyCategory === survey.surveyCategory) {
                        if (banner.refPeriod) {
                            if (banner.refPeriod === survey.refPeriod) {
                                display = true;
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            display = true;
                            return true;
                        }
                    }
                }
            });
        } else if (!banner.surveyCategory) {
            if (this.route === '/' || this.route.includes('portal') || this.route.includes('login') || this.route.includes('register')) {
                return path === 'all' || path === 'external';
            } else if (this.route.includes('surveys') || this.route.includes('case-list') || this.route.includes('submit-case') ||
                    this.route.includes('case-detail') || this.route.includes('add-comment')) {
                return path === 'all' || path === 'internal';
            } else {
                return path === 'all' || path === 'external';
            }
        }
        return display;
    }

    hideBanner(visibleBanner: EcorrVisibleBanner): void {
        this.deleteFromSet(this.bannerSet, visibleBanner);
    }

    ngOnDestroy() {
        if (this.ecorrBanner$) {
            this.ecorrBanner$.unsubscribe();
        }
        if (this.sub$) {
            this.sub$.unsubscribe();
        }
        if (this.user$) {
            this.user$.unsubscribe();
        }
    }
}
