import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
   
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
          // Add Cache-Control and Pragma headers to prevent IE caching responses
        const clonedRequest = req.clone({ headers: req.headers.append( 'Cache-Control', 'private,no-store' ).append( 'Pragma', 'private,no-store' ) });
        return next.handle( clonedRequest );
    }
}