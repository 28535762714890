import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ecorEnv } from 'environments/environment';
import { Observable, of } from 'rxjs';

export interface AppSettings {
    pegaIntegration: boolean
}

@Injectable()
export class AppSettingsService {
    public responseCache = new Map();
    constructor( private httpClient: HttpClient ) {}

    getAppSettings(): Observable<AppSettings> {
        const url = ecorEnv.apiEndpoint + '/secure/settings';

        const settingsCache = this.responseCache.get( url );
        if ( settingsCache ) {
            return of( settingsCache );
        }
        const response = this.httpClient.get<AppSettings>( url, { responseType: 'text' as 'json' } );
        response.subscribe( settings => this.responseCache.set( url, settings ) );
        return response;
    }
}
