<!-- Basic header -->
<a id="uscb-nav-skip-header" class="uscb-nav-skip uscb-button-medium uscb-secondary-button uscb-position-absolute" (click)="focusOnMain()" (keyup.enter)="focusOnMain()" tabindex="1">Skip to Main Content</a> 

<!-- USA Banner -->
<app-ecorr-usa-banner></app-ecorr-usa-banner>

<mat-toolbar class="basic-header sc-header bottom-padding-lg uscb-layout-row">
    <div class="uscb-layout-row uscb-layout-column-lg uscb-layout-row-gt-lg uscb-layout-align-space-between uscb-width-100">
        <div class="uscb-layout-column uscb-layout-row-xs uscb-layout-row-lg uscb-flex-row-gt-lg-50 uscb-flex-row-lg-100 uscb-layout-align-space-between">
            <div class="uscb-layout-row uscb-layout-column-lt-sm uscb-margin-L-gt-md-50 uscb-margin-L-md-20 sc-logo-title">
                <img src="/assets/img/census-logo-black.svg" alt="United States Census Bureau Logo" />
                <div class="uscb-layout-row uscb-layout-align-vert-center uscb-padding-L-20">
                    <h1 class="uscb-title-1 uscb-color-black">
                        {{'sc24Header.surveyTitle' | translate}}
                    </h1>
                </div>
            </div>
        </div>
        <div class="uscb-layout-column uscb-layout-row-xs uscb-layout-row-lg uscb-flex-row-gt-lg-50 uscb-flex-row-lg-100 uscb-layout-align-space-between uscb-layout-align-end-end uscb-layout-align-center-end-lg">
            <div class="uscb-layout-row uscb-padding-R-gt-md-50 uscb-padding-R-md-20 uscb-layout-align-space-between uscb-layout-align-end-end uscb-layout-align-center-end-lg sc-header-links">
                <button *ngIf="faqs?.length" (click)="onFAQOpen()" class="uscb-primary-button" tabindex="0">{{'sc24Header.faqButton' | translate}}</button>
                <span role="presentation" aria-hidden="true">&nbsp;&nbsp;</span>
                <button (click)="updateLanguage()" class="uscb-primary-button" tabindex="1">{{ selectedLanguage === 'en' ? 'Español' : 'English'  }}</button>
            </div>
        </div>
    </div>
</mat-toolbar>
