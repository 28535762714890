import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HeaderFaqDialogData } from './header-faq-dialog-data';
import { HeaderFaqDialogComponent } from './header-faq-dialog.component';

@Injectable()
export class HeaderFaqDialogService {
    constructor(private dialog: MatDialog) { }
    matDialogRef: MatDialogRef<HeaderFaqDialogComponent>;

    open(headerFaqDialogData: HeaderFaqDialogData) {
        const matDialogConfig = new MatDialogConfig();
        matDialogConfig.panelClass = 'myapp-dialog';
        matDialogConfig.minWidth = '50%';
        matDialogConfig.width = '70%';
        matDialogConfig.data = headerFaqDialogData;
        matDialogConfig.disableClose = false;
        matDialogConfig.hasBackdrop = true;
        this.matDialogRef = this.dialog.open(HeaderFaqDialogComponent, matDialogConfig);
    }

}