<section class="ecorr-footer sc-footer uscb-font-sm">
    <div class="ecorr-footer-container sc-footer-container uscb-layout-column-xs uscb-layout-column-sm uscb-layout-row-gt-sm uscb-layout-align-space-between uscb-layout-align-start-center-md uscb-layout-align-vert-center uscb-padding-LR-20 uscb-padding-TB-10">
        <div class="uscb-layout-column uscb-layout-row-sm uscb-flex-row-gt-md-40 uscb-flex-row-sm uscb-flex-row-gt-sm-40 uscb-layout-align-center-start uscb-color-primary">
            <button class="uscb-text-link uscb-padding-0 uscb-layout-align-flex-start sc-omb" (click)="onOMBOpen()">
                <span>{{'sc24Footer.ombText' | translate: {ombNumber: ombNumber} }}</span>
                <br/>
                <span>{{'sc24Footer.expiresText' | translate: {expirationDate: expiryDate} }}</span>
            </button>
        </div>

        <div class="uscb-layout-column uscb-layout-row-sm uscb-flex-row-gt-md-60 uscb-flex-row-sm uscb-flex-row-gt-sm-60 uscb-layout-align-space-between uscb-layout-align-center-end">
            <div class="ecorr-footer-container-links uscb-layout-row uscb-layout-align-center-sm uscb-layout-align-flex-end" fxLayout="row wrap">
                <button class="uscb-padding-10 uscb-text-link">
                    <a href="https://www.census.gov/about/policies/privacy/privacy-policy.html#accessibility" target="_blank" class="uscb-text-link">
                        {{'sc24Footer.accessibilityButton' | translate}} <i aria-label="pop out" class="icon-link fa fa-external-link uscb-text-decoration-none uscb-padding-R-0"></i>
                    </a>
                </button>
                <button class="uscb-padding-10 uscb-text-link">
                    <a href="https://www.census.gov/about/policies/privacy/privacy-policy.html#privacy" target="_blank" class="uscb-text-link">
                        {{'sc24Footer.privacyButton' | translate}} <i aria-label="pop out" class="icon-link fa fa-external-link uscb-text-decoration-none uscb-padding-R-0"></i>
                    </a>
                </button>
                <button class="uscb-padding-10 uscb-text-link" (click)="onSecurityOpen()">
                    <span>{{'sc24Footer.securityButton' | translate}}</span>
                </button>
                <button class="uscb-padding-10 uscb-text-link" (click)="onContactOpen()">
                    <span>{{'sc24Footer.contactButton' | translate}}</span>
                </button>
            </div>
        </div>
    </div>

</section>
