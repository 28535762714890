import { SurveyCard } from '@/surveys/states/surveys/survey-card';

export class PortalUser {
    readonly companyId: number;
    readonly userAccountId: number;
    readonly roleTypeId: number;
    readonly showSendMessage: boolean;
    readonly respondentTypes: string[];
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly phone: string;
    readonly addressLine_1: string;
    readonly addressLine_2: string;
    readonly addressLine_3: string;
    readonly city: string;
    readonly state: string;
    readonly county: string;
    readonly country: string;
    readonly postalCd: string;
    readonly companyName: string;
    readonly activeSurveyCards: SurveyCard[];
    readonly inActiveSurveyCards: SurveyCard[];
}
