<section class="ecorr-footer uscb-hide-lg uscb-hide-md uscb-font-sm uscb-hide-sm uscb-hide-xs">
    <div class="ecorr-footer-container uscb-layout-row uscb-layout-align-space-between uscb-layout-align-vert-center uscb-padding-LR-20">
        <div class="ecorr-text-bold" [ngClass]="{'uscb-color-primary': hideVersion}">
            {{versionNumber}}
        </div>

        <div class="ecorr-footer-container-links uscb-layout-align-center-start" fxLayout="row wrap">
            <a href="https://www.census.gov/careers">Census Jobs</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.census.gov/quality/">Information Quality</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.census.gov/datalinkage">Data Linkage Infrastructure</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.census.gov/privacy">Data Protection and Privacy Policy</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.census.gov/about/policies/privacy/privacy-policy.html#accessibility">Accessibility</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.census.gov/foia">FOIA</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.commerce.gov/">U.S. Department of Commerce</a>
            <span role="presentation" aria-hidden="true">&nbsp;|&nbsp;</span>
            <a href="https://www.usa.gov/">USA.gov</a>
        </div>
        
        <div class="ecorr-text-bold" [ngClass]="{'uscb-color-primary': hideVersion}">
            {{versionNumber}}
        </div>
    </div>
    
    <hr class="uscb-width-100 uscb-margin-0" role="presentation"/>
    
    <div class="ecorr-footer-container uscb-layout-align-center-center uscb-font-1x">
        Measuring America's People, Places, and Economy
    </div>
</section>
