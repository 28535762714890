import { ecorEnv } from "@/../environments/environment";
import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SurveyCard } from "@/surveys/states/surveys/survey-card";
import { ScreenSizeService } from "@/common/screen-size.service";

@Injectable()
export class SurveyViewsInstanceService {
    constructor(
        private httpClient: HttpClient,
        private screenSizeService: ScreenSizeService,
    ) {}

    // accessCenturion(surveyInstanceId: number, userAccountId: number): Observable<any> {
    //     const url = ecorEnv.apiEndpoint + '/secure/surveyInstances/access';
    //     const params = new HttpParams()
    //         .set('surveyInstanceId', toString())
    //         .set('userAccountId', userAccountId.toString())
    //         .set('paradata', null);

    //     return this.httpClient.get<any>(url, { params: params })
    //         .pipe(
    //             catchError(this.handleError)
    //         );
    // }

    accessCenturionV1(
        surveyInstanceId: number,
        userAccountId: number
    ): Observable<any> {
        const url = ecorEnv.apiEndpoint + "/secure/surveyInstances/access";
        const params = new HttpParams()
            .set('surveyInstanceId', surveyInstanceId.toString())
            .set('userAccountId', userAccountId.toString())
            .set('paradata', null);

        return this.httpClient
            .get<any>(url, { params: params })
            .pipe(catchError(this.handleError));
    }

    accessCenturionV2(
        survey: SurveyCard,
        userAccountId: number
    ): Observable<any> {
        const url = ecorEnv.apiEndpoint + "/secure/surveys/centurion/token";
        //console.log("url: "+ url);
        const params = new HttpParams()
            .set('surveyInstanceId', survey.surveyInstanceId.toString())
            .set('caseId', survey.caseId.toString())
            .set('userAccountId', userAccountId.toString())
            .set('jbid', '')
            .set('screenSize', this.screenSizeService.getScreeSize())
            .set('sub','')
            .set('language', localStorage.getItem('language') == null ? 'en': localStorage.getItem('language') );

            console.log("language code:::", localStorage.getItem('language'));
            console.log("parameters provided to service:::", params.toString());

        // .set('centurionUrl', survey.centurionUrl.toString());

        return this.httpClient
            .get<any>(url, { params: params})
            .pipe(catchError(this.handleError));
    }

    accessCenturionV2WithParams(queryParams: any): Observable<any> {
        const url = ecorEnv.apiEndpoint + "/secure/surveys/centurion/token";
        //console.log("url: "+ url);
        const params = new HttpParams()
            .set('surveyInstanceId', queryParams.surveyInstanceId)
            .set('caseId', queryParams.caseId)
            .set('userAccountId', queryParams.userAccountId)
            .set('jbid', '')
            .set('screenSize', this.screenSizeService.getScreeSize())
            .set('sub','')
            .set('language', localStorage.getItem('language') == null ? 'en': localStorage.getItem('language') );

            console.log("language code:::", localStorage.getItem('language'));
            console.log("parameters provided to service:::", params.toString());

        // .set('centurionUrl', survey.centurionUrl.toString());

        return this.httpClient
            .get<any>(url, { params: params})
            .pipe(catchError(this.handleError));
    }


    formPost(url: string, smartId: string): Observable<any> {
        const params = new HttpParams().set("authCode", smartId);

        return this.httpClient
            .post<any>(url, null, { params })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        console.error("surveys-view-instance-base", "error", error);
        if (error.error instanceof ErrorEvent) {
            console.error(
                "surveys-view-instance-base",
                "client side error:",
                error.error.message
            );
        } else {
            return throwError("Error-111111");
        }
        return throwError("Error-222222");
    }
}
