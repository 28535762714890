import { ecorEnv } from '@/../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService, Languages } from '@/common/services/language.service';
import { HelpDialogData } from '@/landing/dialogs/help-dialog/help-dialog-data';
import { HelpDialogService } from '@/landing/dialogs/help-dialog/help-dialog.service';

@Component({
    selector: 'app-ecorr-sc24-footer',
    templateUrl: './ecorr-sc24-footer.component.html'
})
export class EcorrSC24FooterComponent implements OnInit {
    languageSub$: Subscription;
    selectedLanguage: string = 'en';
    language: Languages;

    ombNumber = '0607-0368';
    expiryDate = '12/31/2025';
    hideVersion = false;

    constructor(private languageService: LanguageService,
        private helpDialogService: HelpDialogService) { 
    }

    ngOnInit() {
        this.languageSub$ = this.languageService.language$.subscribe({
            next: (res) => {
                this.language = res;
                this.selectedLanguage = this.language.languageCode;
            },
            error: (err) => {
                console.log(err);
            }
        })
    }

    onOMBOpen() {
        let options: HelpDialogData = {
            title: this.languageService.getTranslation('sc24Dialogs.ombDialog.title'),
            content: this.languageService.getTranslation('sc24Dialogs.ombDialog.content'),
            closeButtonLabel: this.languageService.getTranslation('sc24Dialogs.closeLabel'),
            dialogClass: 'sc-dialog'
        };
        this.helpDialogService.open(options);
    }

    onSecurityOpen() {
        let options: HelpDialogData = {
            title: this.languageService.getTranslation('sc24Dialogs.securityDialog.title'),
            content: this.languageService.getTranslation('sc24Dialogs.securityDialog.contentHtml'),
            closeButtonLabel: this.languageService.getTranslation('sc24Dialogs.closeLabel'),
            dialogClass: 'sc-dialog'
        };
        this.helpDialogService.open(options);
    }

    onContactOpen() {
        let options: HelpDialogData = {
            title: this.languageService.getTranslation('sc24Dialogs.contactDialog.title'),
            content: this.languageService.getTranslation('sc24Dialogs.contactDialog.contentHtml'),
            closeButtonLabel: this.languageService.getTranslation('sc24Dialogs.closeLabel'),
            dialogClass: 'sc-dialog'
        };
        this.helpDialogService.open(options);
    }

    ngOnDestroy() {
        if (this.languageSub$) {
            this.languageSub$.unsubscribe();
        }
    }
}
