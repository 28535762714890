import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IdleTimeoutDialogComponent } from './idle-timeout-dialog.component';
import { IdleTimeoutDialogService } from './idle-timeout-dialog.service';


@NgModule({
    declarations: [IdleTimeoutDialogComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        MatDialogModule
    ],
    exports: [IdleTimeoutDialogComponent],
    providers: [IdleTimeoutDialogService]
})
export class IdleTimeoutDialogModule { }
