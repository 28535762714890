<section *ngIf="bannerSet && bannerSet.size > 0" class="ecorr-banner">
    <div *ngFor="let visibleBanner of bannerSet">
        <div *ngIf="visibleBanner.show">
            <section [style.background-color]="visibleBanner.color"
                class="ecorr-banner-{{visibleBanner.type}} data-uscb-banner-alert-container uscb-overflow-hidden uscb-layout-column uscb-layout-align-center-center uscb-position-relative uscb-banner-height">
                <button (click)="hideBanner(visibleBanner)" (keyup.enter)="hideBanner(visibleBanner)" *ngIf="visibleBanner.closable==='y'"
                    class="uscb-banner-alert-content uscb-button uscb-icon-button uscb-banner-alert-dismiss data-uscb-banner-dismiss-btn uscb-padding-T-5 uscb-padding-LR-md-20 uscb-padding-LR-gt-md-60" tabindex="0">
                    <span class="o-close-1 data-uscb-banner-dismiss-btn uscb-color-black uscb-font-2_5x"></span>
                </button>
                <div
                    class="uscb-banner-alert-content uscb-layout-row uscb-w-100 uscb-layout-align-space-between uscb-layout-align-vert-center uscb-padding-T-5 uscb-padding-L-md-20 uscb-padding-L-gt-md-60 uscb-padding-R-md-60 uscb-padding-R-gt-md-100">
                    <i class="fa fa-lg {{visibleBanner.icon}}" width="21" length="21"></i>
                    <div
                        class="uscb-body-text-roboto uscb-bold uscb-line-height-24 uscb-title-1 uscb-banner-alert-mobile-banner uscb-color-black uscb-text-align-left uscb-margin-TB-0 uscb-padding-B-5 uscb-width-100 uscb-margin-L-10 data-uscb-banner-hover-target">
                        {{visibleBanner.trustedContent}}
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>