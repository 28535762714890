import { ecorEnv } from '@/../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ecorr-footer',
    templateUrl: './ecorr-footer.component.html'
})
export class EcorrFooterComponent implements OnInit {
    constructor() { }

    versionNumber = 'v5.0.1';
    hideVersion = false;

    ngOnInit() {
        if (ecorEnv.isProd()) {
            this.hideVersion = true;
            this.versionNumber = '';
        }
    }
}
