import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';
import { HeaderFaqDialogComponent } from './header-faq-dialog.component';
import { HeaderFaqDialogService } from './header-faq-dialog.service';

@NgModule({
    declarations: [HeaderFaqDialogComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        MatDialogModule,
        NgxMaskModule
    ],
    exports: [HeaderFaqDialogComponent, MatDialogModule],
    providers: [HeaderFaqDialogService]
})
export class HeaderFaqDialogModule { }
