import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HeaderFaqDialogData } from '../../dialogs/header-faq-dialog/header-faq-dialog-data';
import { HeaderFaqDialogService } from '../../dialogs/header-faq-dialog/header-faq-dialog.service';
import { Languages, LanguageService } from '../../../common/services/language.service';
import { HeaderFaq } from '@/landing/dialogs/header-faq-dialog/header-faq';

@Component({
    selector: 'app-ecorr-sc24-header',
    templateUrl: './ecorr-sc24-header.component.html'
})
export class EcorrSC24HeaderComponent implements OnInit {

    dropdownIcon = '/assets/img/arrow_drop_down.svg';
    currentUrl: any = ''
    loading = false;

    languageSub$: Subscription;

    availLangs: Languages[];
    languageControl = new FormControl();
    selectedLanguage: string = 'en';
    language: Languages;

    faqs: HeaderFaq[] = [];

    constructor( private languageService: LanguageService,
        private headerFaqDialogService: HeaderFaqDialogService ) { }

    ngOnInit() {
        this.availLangs = this.languageService.availLang;
        this.languageSub$ = this.languageService.language$.subscribe({
            next: (res) => {
                this.language = res;
                this.selectedLanguage = this.language.languageCode;
                this.getFAQs();
            },
            error: (err) => {
              console.log(err);
            }
        })
    }

    ngAfterViewInit(): void {
        this.languageControl.valueChanges.subscribe((languageCode) => {
            this.languageService.setLanguage(languageCode);
        });
    }

    updateLanguage() {
        this.languageService.setLanguage(this.selectedLanguage === 'en' ? 'es' : 'en');
    }

    ngOnDestroy() {
        if (this.languageSub$) {
            this.languageSub$.unsubscribe();
        }
    }

    focusOnMain() {
        const mainContent: HTMLElement = document.querySelector('#main');
        if (mainContent) {
            mainContent.focus();
        }
    }

    getFAQs(): void {
        this.faqs = [];
        let translations = this.languageService.getTranslation('sc24Dialogs.faqDialog.faqs');
        for (let translation of translations){
            let question = translation.question;
            let answer = translation.answer;
            this.faqs.push({question: question, answer: answer, show: false});
        }
    }
      
    onFAQOpen():void {
        this.getFAQs();
        const options: HeaderFaqDialogData = {
            title: this.languageService.getTranslation('sc24Dialogs.faqDialog.title'),
            closeButtonLabel: this.languageService.getTranslation('sc24Dialogs.closeLabel'),
            dialogClass: 'sc-dialog',
            faq: this.faqs
        };
        this.headerFaqDialogService.open(options);
    }

}
