import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DelegateRoutingModule } from './delegate-routing.module';
import { DelegateComponent } from './delegate.component';


@NgModule({
  declarations: [DelegateComponent],
  imports: [
    CommonModule,
    DelegateRoutingModule
  ]
})
export class DelegateModule { }
