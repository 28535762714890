import { createFeatureSelector, createSelector } from '@ngrx/store';
import { portalUserFeatureKey } from './portal-user.reducer';
import { PortalUserState } from './portal-user.state';

const portalUserStateSelector = createFeatureSelector<PortalUserState>(portalUserFeatureKey);

export const portalUserSelector = createSelector(
    portalUserStateSelector,
    (state: PortalUserState) => state.portalUser
);
