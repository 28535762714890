<!-- Basic header -->
<a id="uscb-nav-skip-header" class="uscb-nav-skip uscb-button-medium uscb-secondary-button uscb-position-absolute" (click)="focusOnMain()" (keyup.enter)="focusOnMain()" tabindex="1">Skip to Main Content</a>

<!-- USA Banner -->
<app-ecorr-usa-banner></app-ecorr-usa-banner>

<mat-toolbar class="basic-header bottom-padding-md uscb-layout-row">
    <div class="uscb-layout-column uscb-margin-L-gt-sm-50 uscb-margin-L-sm-20">
        <a href="https://www.census.gov">
            <img alt="census logo" src="/assets/img/census-logo-white.svg"/>
        </a>
    </div>
</mat-toolbar>
