import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderFaqDialogData } from './header-faq-dialog-data';
import { HeaderFaq } from './header-faq';

@Component({
    selector: 'app-header-faq-dialog',
    templateUrl: './header-faq-dialog.component.html'
})
export class HeaderFaqDialogComponent implements OnInit, OnDestroy {
    browserCheckDisplay = 'FAQ';
    loading = false;
    error = '';
    faqList: HeaderFaq[] = [];
    openedAccordion: number = 1;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public headerFaqDialogData: HeaderFaqDialogData,
        private matDialogRef: MatDialogRef<HeaderFaqDialogComponent>,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.faqList = this.headerFaqDialogData.faq;
    }

    toggleAccordion(idx: number, event: any): void {
        if (event.target.id === "browserCheckLink") {
            return;
        }
        const targetClass = event.target.classList;

        let root = null;

        // assume user did not hit exactly where the banner is
        if (targetClass.value.length == 0 && event.target.parentElement.classList.contains('uscb-accordion-header-content')) {
            root = event.target.parentElement.parentElement;
        } else if (targetClass.contains('uscb-accordion-header') || targetClass.contains('uscb-accordion_panel')) {
            if (root !== null) {
                root = root.firstElementChild;
            }
        } else if (targetClass.contains('uscb-font-sm')) {
            if (root !== null) {
                root = root.parentElement.firstElementChild;
            }
        } else {
            if (event instanceof KeyboardEvent && event.key === 'Enter' || event.keyCode === 13) {
                root = event.target.firstChild;
            } else {
                root = event.target.parentElement;
            }
        }

        if (root === null) {
            return;
        }

        const icon = root.firstElementChild;
        const content = root.nextElementSibling;

        this.faqList[idx].show = !this.faqList[idx].show;

        if (this.faqList[idx].show) { 
            this.renderer.removeClass(icon, 'o-add-1');
            this.renderer.addClass(icon, 'o-minus-1');
            this.renderer.removeClass(content, 'uscb-accordion-show-panel');
            this.renderer.setAttribute(root, 'aria-expanded', 'true'); 
        } else {
            this.renderer.removeClass(icon, 'o-minus-1');
            this.renderer.addClass(icon, 'o-add-1');
            this.renderer.addClass(content, 'uscb-accordion-show-panel');
            this.renderer.setAttribute(root, 'aria-expanded', 'false');
        }
    }

    close(value: any) {
        this.matDialogRef.close(value);
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.close('ESC keydown');
    }

    ngOnDestroy() {
    }
}
