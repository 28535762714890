import { AfterViewInit, Component, Input, OnInit, QueryList, SimpleChange, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-ecorr-browser-check',
    templateUrl: './ecorr-browser-check.component.html',
    styleUrls: ['./ecorr-browser-check.component.scss']
})
export class EcorrBrowserCheckComponent implements OnInit, AfterViewInit {

    @Input() display: string = '';
    default = 'default';
    @Input() addedToPage?: number = 0;
    
    constructor(
        private sanitized: DomSanitizer) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.addBrowserCheckLink();
    }

    ngOnChanges(changes: SimpleChange) {
        setTimeout(() => {
            this.addBrowserCheckLink()
        }, 500);
    }

    ngOnDestroy() {
    }

    displayDefaultHtml() {
        return this.display === this.default;
    }

    browserCheckShow() {
        document.querySelector('#browser-check').dispatchEvent(new Event('force-show'));
    }
    browserCheckHide() {
        document.querySelector('#browser-check').dispatchEvent(new Event('force-hide'));
    }

    addBrowserCheckLink() {
        let browserCheckLink = document.getElementById("browserCheckLink");
        if (browserCheckLink) {
            if (!this.displayDefaultHtml()) {
                browserCheckLink.setAttribute("class", "uscb-text-link uscb-cursor-pointer-hover");
            }
            browserCheckLink.setAttribute("tabindex", "0");
            browserCheckLink.addEventListener('click', (e) => {
                this.browserCheckShow();
            });
            browserCheckLink.addEventListener('keydown', (e) => {
                if (e.key == "Enter") {
                    this.browserCheckShow();
                }
            });
        }
    }
}
