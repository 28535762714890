import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpDialogData } from './help-dialog-data';

@Component({
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html'
})
export class HelpDialogComponent implements OnInit, OnDestroy {
    dialogStyle: string = '';
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public helpDialogData: HelpDialogData,
        private matDialogRef: MatDialogRef<HelpDialogComponent> ) {
    }

    ngOnInit() {
    }

    close(value: any) {
        this.matDialogRef.close(value);
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.close('ESC keydown');
    }

    ngOnDestroy() {
    }
}
