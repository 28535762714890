import { ecorEnv } from '@/../environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FaqSubtopicData } from './faq-subtopic-data';
import { FaqSubtopicDetail } from './faq-subtopic-detail';


@Injectable({
    providedIn: 'root'
})
export class FaqService {
    subtopicList: string[] = ['FAQ Registering', 'FAQ Account Edits', 'FAQ Log in', 'FAQ Survey', 'FAQ Technical Issues'];

    constructor(private httpClient: HttpClient) { }

    getFAQsBySubtopic(subtopic: string): Observable<FaqSubtopicData> {
        const faqUrl = ecorEnv.apiEndpoint + '/faqs?subtopic=' + subtopic;
        const params = new HttpParams();

        return this.httpClient.get<FaqSubtopicData>(faqUrl, { params: params })
            .pipe(
                catchError(this.handleErrorFAQSubtopics)
            );
    }


    getFAQDetails(insKey: string): Observable<FaqSubtopicDetail> {
        const faqUrl = ecorEnv.apiEndpoint + '/faq/details?InsKey=' + insKey;
        const params = new HttpParams();

        return this.httpClient.get<FaqSubtopicDetail>(faqUrl, { params: params })
            .pipe(
                catchError(this.handleErrorFAQDetails)
            );

    }

    private handleErrorFAQSubtopics(error: HttpErrorResponse) {
        console.error('FAQ subtopics', 'error', error);
        if (error.error instanceof ErrorEvent) {
            console.error('FAQ subtopics', 'client side error:', error.error.message);
        } else {
            return throwError('Error getting FAQ subtopics');
        }
        return throwError('Error getting FAQ subtopics');
    }


    private handleErrorFAQDetails(error: HttpErrorResponse) {
        console.error('FAQ details', 'error', error);
        if (error.error instanceof ErrorEvent) {
            console.error('FAQ details', 'client side error:', error.error.message);
        } else {
            return throwError('Error getting FAQ details');
        }
        return throwError('Error getting FAQ details');
    }
}
