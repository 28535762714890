import { ecorEnv } from '@../../environments/environment';
import { EcorrUtilsService } from '@/common/utils/ecorr-utils.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SessionStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { AppService } from './app.service';
import { IdleTimeoutDialogData } from './support/dialogs/idle-timeout-dialog/idle-timeout-dialog-data';
import { IdleTimeoutDialogService } from './support/dialogs/idle-timeout-dialog/idle-timeout-dialog.service';
import { EcorrEvent } from './surveys/services/event.service';
import { FeaturesService } from './features.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    idleTimeout$: Subscription;
    env: string = ecorEnv.env;

    currentPath: String;
    ref: any;
    isSC24 = false;

    constructor(
        private idle: Idle, 
        private keepalive: Keepalive,
        private router: Router,
        public dialog: MatDialog,
        private idleTimeoutDialogService: IdleTimeoutDialogService,
        location: Location,
        private sessionStorageService: SessionStorageService,
        public appService: AppService,
        private ecorrUtilsService: EcorrUtilsService,
        private featuresService: FeaturesService
    ) {

        this.ref = document.body;

        idle.setIdle(900);
        idle.setTimeout(300);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.closeIdleTimeoutDialog();
            // console.log('[onIdleEnd] IdleTimeoutDialog is closed!');
            this.idleState = 'No longer idle.';
            // console.log(this.idleState);
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.idleTimeoutDialogService.close();
            this.idleState = 'Timed out!';
            this.timedOut = true;
            // console.log(this.idleState);
            this.sessionStorageService.store('timedOut', true);
            this.ecorrUtilsService.logout();
        });

        idle.onIdleStart.subscribe(() => {
            this.idle.clearInterrupts();
            this.idleState = 'You have gone idle!';
            // console.log(this.idleState);
            this.openIdleTimeoutDialog();
        });

        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        // Lets check the path everytime the route changes, stop or start the idle check as appropriate.
        this.router.events.subscribe((val) => {
            this.currentPath = location.path();
            if (this.currentPath.includes('/portal') ||
                this.currentPath.includes('/login') ||
                this.currentPath.includes('/register') ||
                this.currentPath.includes('/404-not-found') ||
                this.currentPath.includes('/faq')) {
                idle.stop();
                this.isSC24 = false;
            } else if (this.currentPath.includes('/specialcensus')) {
                this.isSC24 = true;
            } else {
                idle.watch();
                this.isSC24 = false;
            }
        });
    }

    ngOnInit() {
        /**
         * Clickjack prevention
         * 
         * <style id="antiClickjack">
         *     body {
         *         display: none !important;
         *     }
         * 
         * </style>
         * <script type="text/javascript">
         *     if (self === top) {
         *         var antiClickjack = document.getElementById("antiClickjack");
         *         antiClickjack.parentNode.removeChild(antiClickjack);
         *     } else {
         *         top.location = self.location;
         *     }
         * </script>
         */
        const antiClickjack = document.getElementById('antiClickjack');
        if (antiClickjack) {
            antiClickjack.parentNode?.removeChild(antiClickjack);
        }
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    openIdleTimeoutDialog() {
        const options: IdleTimeoutDialogData = {
            title: 'Warning - Session Expiring',
            content: 'Your session is set to expire in {{countdown  | date: "mm:ss"}} minutes. Please select "Continue" to extend your session or you will be signed out',
            confirmButtonLabel: 'Continue'
        };

        this.idleTimeoutDialogService.open(options);

        this.idleTimeout$ = this.idleTimeoutDialogService.continued().subscribe(res => {
            console.log('[openIdleTimeoutDialog]', 'Received from dialog', res);
        });
    }

    closeIdleTimeoutDialog() {
        this.idleTimeoutDialogService.close();
    }
}
