
import { createAction, props } from '@ngrx/store';
import { PortalUser } from './portal-user';

enum PortalUserActionTypes {
    SET_USER = '[PortalUserState] SET_USER'
}

export const setUser = createAction(PortalUserActionTypes.SET_USER, props<{ payload: { portalUser: PortalUser } }>());

