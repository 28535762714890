export class EcorrVisibleBanner {
    trustedContent: string;
    icon: string;
    displayLocation: string;
    color: string;
    show: boolean;
    closable: string;
    type: string;
    id: string;
}
