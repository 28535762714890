<section [class]="helpDialogData.dialogClass">
    <div class="uscb-layout-row uscb-bg-primary uscb-color-white">
        <span class="vcenter uscb-flex uscb-padding-TB-0 uscb-padding-LR-8">{{helpDialogData.title}}</span>
        <span class="vcenter">
            <mat-icon (click)="close(true)" (keyup.enter)="close(true)" tabindex="0" aria-hidden="false">close</mat-icon>
        </span>
    </div>

    <mat-dialog-content class="sc-24-dialog-content">
        <div class="uscb-layout-row uscb-layout-column-md">
            <div class="uscb-width-100 uscb-layout-column-md uscb-padding-B-5">
                <span class="md-text top-padding" [innerHTML]="helpDialogData.content"></span>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="uscb-layout-row-gt-md uscb-layout-column-md uscb-layout-column-sm uscb-layout-align-end-end">
        <button type="button" class="uscb-primary-button" (click)="close(true)" (keyup.enter)="close(true)" tabindex="0">
            {{helpDialogData.closeButtonLabel}}
        </button>
    </mat-dialog-actions>
</section>