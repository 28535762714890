import { ParadataEventService } from '@/common/paradata-event/paradata-event.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { DelegateService } from './delegate.service';

@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html'
})
export class DelegateComponent implements OnInit, OnDestroy {
  paramSub$: Subscription;
  delegateSub$: Subscription;
  delegationId: string;
  requestActive: string;


  constructor(private route: ActivatedRoute,
    private router: Router,
    private delegateService: DelegateService,
    private sessionStorageService: SessionStorageService,
    private paraDataEventService: ParadataEventService) {
  }


  ngOnInit(): void {
    this.paramSub$ = this.route.params.subscribe(params => {
      this.delegationId = params['delegationId'];

      if (this.delegationId.length > 0) {
        this.delegateSub$ = this.delegateService.verifyDelegationRequest(this.delegationId).subscribe(
            (response) => {
              this.requestActive = response.requestActiveIndicator;
              this.sessionStorageService.store('requestActive', this.requestActive);
              this.router.navigate(['/portal']);
            },
            (error: any) => {
              if (error.status === 400) {
                this.paraDataEventService.getAndClear();    
                this.sessionStorageService.clear('requestActive');
              }
              this.router.navigate(['/portal']);
            }
        );
      }
    });
  }


  ngOnDestroy(): void {
    if (this.paramSub$) {
      this.paramSub$.unsubscribe();
    }

    if (this.delegateSub$) {
      this.paramSub$.unsubscribe();
    }
  }
}
