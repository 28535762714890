import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';

export interface Languages {
    languageName: string,     //'English'
    languageCode: string  //'en'
}

@Injectable()
export class LanguageService {

    availLang: Languages[] = [
        {languageName: 'English', languageCode: 'en'},
        {languageName: 'Español', languageCode: 'es'}
    ];

    constructor( public translate: TranslateService ) {
        // Register translation languages
        translate.addLangs(['en', 'es']);
        // Set default language
        translate.setDefaultLang('en');
    }

    // Create Observable for language 
    private languageStore = new BehaviorSubject<any>(this.availLang[0]);
    language$ = this.languageStore.asObservable();

    getLanguage() {
        return this.languageStore.getValue();
    }
    
    setLanguage(languageCode: string): void {
        let nextLanguage = this.availLang.find(lang => lang.languageCode === languageCode);
        if (!nextLanguage) {
            nextLanguage = this.availLang.find(lang => lang.languageCode === 'en');
        }
        this.languageStore.next(nextLanguage);

        this.translate.use(languageCode);
        localStorage.setItem('language', languageCode);
    }

    getTranslation(jsonKey: string) {
        return this.translate.instant(jsonKey);
    }
}
