import { Action, createReducer, on } from '@ngrx/store';
import * as PortalUserActions from './portal-user.action';
import { initialState, PortalUserState } from './portal-user.state';

export const portalUserFeatureKey = 'portalUserState';

const newPortalUserReducer = createReducer(
    initialState,
    on(PortalUserActions.setUser,
        (state, props) => {
            return {
                ...props.payload
            };
        })
);

export function portalUserReducer(state: PortalUserState, action: Action) {
    return newPortalUserReducer(state, action);
}