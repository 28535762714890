import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DelegateModule } from './surveys/delegate/delegate.module';
import { SurveyReportNowComponent } from './surveys/components/survey-report-now/survey-report-now.component';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
    {
        path: 'portal',
        loadChildren: () => import('./auth/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'specialcensus',
        loadChildren: () => import('./landing/special-census/special-census.module').then(m => m.SpecialCensusModule)
    },
    {
        path: 'surveys',
        loadChildren: () => import('./surveys/states/surveys/surveys.module').then(m => m.SurveysModule)
    },
    {
        path: 'submit-case',
        loadChildren: () => import('./support/states/submit-case/submit-case.module').then(m => m.SubmitCaseModule)
    },
    {
        path: 'case-list',
        loadChildren: () => import('./support/states/case-list/case-list.module').then(m => m.CaseListModule)
    },
    {
        path: 'case-detail/:caseID',
        loadChildren: () => import('./support/states/case-detail/case-detail.module').then(m => m.CaseDetailModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./support/faq/faq.module').then(m => m.FaqModule)
    },
    {
        path: '',
        redirectTo: 'portal',
        pathMatch: 'full'
    },
    /*
    {
        path: 'home',
        redirectTo: 'surveys',
        pathMatch: 'full'
    },
    */
    {
        path: 'delegate/:delegationId',
        // loadChildren: () => import('./surveys/delegate/delegate.module').then(m => m.DelegateModule) 
        loadChildren: () => DelegateModule
    },
    { 
        path: 'survey-report-now',
        component: SurveyReportNowComponent
    },
    {
        path: '404-not-found',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '404-not-found'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
    useHash: false,
    relativeLinkResolution: 'legacy'
}
        )
    ],
    exports: [
        RouterModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy }
    ]
})
export class AppRoutingModule { }
