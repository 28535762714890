import { ecorEnv } from '@/../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { EcorrBanner } from './ecorr-banner';

@Injectable({
    providedIn: 'root'
})

export class EcorrBannerService {

    constructor(private http: HttpClient) { }

    getActiveBanners(displayApp: string): Observable<EcorrBanner[]> {
        // console.log('[EcorrBannerService] [displayApp] ' + displayApp);
        return this.http.get<EcorrBanner[]>(ecorEnv.apiEndpoint + '/banners?displayApp=' + displayApp)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // Error handling 
    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return throwError(errorMessage);
    }

}
