import { AppModule } from '@/../app/app.module';
import { ecorEnv } from '@/../environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (ecorEnv.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

if (!navigator.cookieEnabled) {
    document.getElementById("initialLoading").innerHTML = "Please enable cookies for this site to continue...";
}