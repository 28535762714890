import { ecorEnv } from '@/../environments/environment';
import { PortalUser } from '@/common/components/ecorr-store/portal-user/portal-user';
import * as PortalUserActions from '@/common/components/ecorr-store/portal-user/portal-user.action';
import { PortalUserState } from '@/common/components/ecorr-store/portal-user/portal-user.state';
import { ParadataEventEnum, ParadataEventService } from '@/common/paradata-event/paradata-event.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class EcorrUtilsService {

    constructor(private store: Store<PortalUserState>,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private paradataEventService: ParadataEventService) { }

    @SessionStorage('timedOut') timedOut: boolean;

    public isIE() {
        const agent = window.navigator.userAgent.toLowerCase();
        return agent.includes('trident') || agent.includes('android');
    }

    logout() {
        this.paradataEventService.add(ParadataEventEnum.SignOut);
        this.paradataEventService.flushParadataevents();
        this.store.dispatch(PortalUserActions.setUser({ payload: { portalUser: new PortalUser() } }));

        /**
         * TODO: 2 of 2. The other TODO is in ecorr.guard the logout observer.
         */
        /** TODO start */
        this.sessionStorageService.clear();
        this.localStorageService.clear();
        /** TODO end */

        let endpoint = null;
        if (ecorEnv.cpassEnabled) {
            if (this.timedOut) {
                endpoint = ecorEnv.logoutUrl + ecorEnv.timeoutParams;
            } else {
                endpoint = ecorEnv.logoutUrl;
            }
        } else {
            endpoint = '/portal';
        }

        setTimeout(() => {
            window.location.href = endpoint;
        }, 200);
    }
}
