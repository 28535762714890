import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ecorEnv } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  features: string[];

  constructor(
    private http: HttpClient
  ) { 
    this.getFeatures().subscribe((features: string[]) => {
      this.features = features;
    });
  }

  getFeatures(): Observable<string[]> {
    return this.http.get<string[]>(`${ecorEnv.apiEndpoint}/features`)
  }

  isBlocked(feature: string) {
    return this.features.includes(feature);
  }
}
