import { AppService } from '@/app.service';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { FaqSubtopic } from './faq-subtopic';
import { FaqSubtopicData } from './faq-subtopic-data';
import { FaqSubtopicDetail } from './faq-subtopic-detail';
import { FaqService } from './faq.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { EcorrBrowserCheckComponent } from '@/common/components/ecorr-browser-check/ecorr-browser-check.component';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit, OnDestroy {
    browserCheckDisplay = 'FAQ';
    loading = false;
    error = '';
    faqList: FaqSubtopic[][] = [];
    sub1$: Subscription;
    sub2$: Subscription;
    openedAccordion: number = 1;

    constructor(private appService: AppService, 
        public faqService: FaqService, 
        private renderer: Renderer2,
        private titleService: Title,
        private sanitized: DomSanitizer) { }

    ngOnInit() {
        // Set descriptive page title
        this.titleService.setTitle("Respondent Portal FAQ Page");
        
        this.appService.loading = false;
        this.getFAQsBySubtopic();
    }

    getFAQsBySubtopic(): void {
        this.loading = true;

        this.faqService.subtopicList.forEach((subTopic) => {
            this.faqList[subTopic] = [];

            this.sub1$ = this.faqService.getFAQsBySubtopic(subTopic)
                .subscribe(
                    (data: FaqSubtopicData) => {
                        data.pxResults.forEach((faqSubItem) => {
                            faqSubItem.label = faqSubItem.ArticleTitle;
                            faqSubItem.key = faqSubItem.pzInsKey;
                            faqSubItem.show = false;

                            this.faqList[subTopic].push(faqSubItem);
                        });
                        this.loading = false;
                    },
                    error => {
                        this.error = error;
                        this.loading = false;
                        console.error(this.error);
                    },
                    () => {
                        // console.log(subTopic, this.faqList[subTopic]);
                        // console.log('FAQ subtopic list is loaded.');
                    }
                );
        });
    }

    toggleAccordion(idx: number, subtopic: string, event: any): void {
        if (event.target.id === "browserCheckLink") {
            return;
        }
        const targetClass = event.target.classList;

        let root = null;

        // assume user did not hit exactly where the banner is
        if (targetClass.contains('uscb-accordion-header') || targetClass.contains('uscb-accordion_panel')) {
            if (root !== null) {
                root = root.firstElementChild;
            }
        } else if (targetClass.contains('uscb-font-sm')) {
            if (root !== null) {
                root = root.parentElement.firstElementChild;
            }
        } else {
            if (event instanceof KeyboardEvent && event.key === 'Enter' || event.keyCode === 13) {
                root = event.target.firstChild;
            } else {
                root = event.target.parentElement;
            }
        }

        if (root === null) {
            return;
        }

        const icon = root.firstElementChild;
        const content = root.nextElementSibling;

        if (!this.faqList[subtopic][idx].content) {
            this.sub2$ = this.faqService.getFAQDetails(this.faqList[subtopic][idx].key)
                .subscribe(
                    (data: FaqSubtopicDetail) => {
                        this.faqList[subtopic][idx].content = this.htmlDecodeToSafe(data.ContentSourceAuthored);
                    },
                    error => {
                        this.error = error;
                        this.loading = false;
                        console.error(this.error);
                    },
                    () => {
                        this.loading = false;
                        this.openedAccordion++;
                        // console.log('FAQ detail is loaded.');
                    }
                );
        }

        this.faqList[subtopic][idx].show = !this.faqList[subtopic][idx].show;

        if (this.faqList[subtopic][idx].show) { 
            this.renderer.removeClass(icon, 'o-angle-down-1');
            this.renderer.addClass(icon, 'o-angle-up-1');
            this.renderer.removeClass(content, 'uscb-accordion-show-panel');
            this.renderer.setAttribute(root, 'aria-expanded', 'true'); 
        } else {
            this.renderer.removeClass(icon, 'o-angle-up-1');
            this.renderer.addClass(icon, 'o-angle-down-1');
            this.renderer.addClass(content, 'uscb-accordion-show-panel');
            this.renderer.setAttribute(root, 'aria-expanded', 'false');
        }
    }

    ngOnDestroy() {
        if (this.sub1$) {
            this.sub1$.unsubscribe();
        }
        if (this.sub2$) {
            this.sub2$.unsubscribe();
        }
    }

    htmlDecodeToSafe(input: string) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        let faqContent = doc.documentElement.textContent;
        if (doc.documentElement.getElementsByTagName("body").length > 0 && !doc.documentElement.getElementsByTagName("body")[0].innerHTML.includes("<script")) {
            faqContent = doc.documentElement.getElementsByTagName("body")[0].innerHTML;
        }
        return this.sanitized.bypassSecurityTrustHtml(faqContent);
    }
}