import { PortalUser } from './portal-user';

export interface PortalUserState {
    readonly portalUser: PortalUser;
}

export const initialState: PortalUserState = {
    portalUser: new PortalUser()
};

