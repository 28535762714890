<nav *ngIf="(portalUser | async)?.userAccountId > 0" class="ecorr-nav">
    <!-- User header -->

    <section class="uscb-layout-row uscb-layout-align-center-center ecorr-user-header uscb-hide-xs uscb-hide-sm uscb-hide-md">
        <mat-toolbar-row class="uscb-flex-row-lg-100 uscb-flex-row-90 uscb-layout-row uscb-flex nav-pc">
            <div class="uscb-flex">
                <button mat-button [class.ecorr-text-navy]="(router.url === '/surveys')" [routerLink]="['surveys']" tabindex="0">
                    <i class="fa fa-tasks"></i>
                    My Surveys
                </button>
                <button mat-button [class.ecorr-text-navy]="(router.url === '/case-list')" [routerLink]="['/case-list']" [disabled]="!settings.pegaIntegration" tabindex="0">
                    <i class="fa fa-comments-o"></i>
                    Message Status
                </button>
                <button mat-button [class.ecorr-text-navy]="(router.url === '/manage-account')" (click)="showAccountSettings()" (keyup.enter)="showAccountSettings()" tabindex="0">
                    <i class="fa fa-cog"></i>Manage Account
                </button>
                <button *ngIf="(portalUser | async)?.activeSurveyCards == null" disabled class="md-button ecorr-button-icon">
                    <i class="fa fa-spinner fa-spin fa-sm"></i>
                </button>
                <button mat-button [class.ecorr-text-navy]="(router.url.includes('/submit-case'))"
                    *ngIf="(portalUser | async)?.showSendMessage && (portalUser | async)?.activeSurveyCards != null"
                    (click)="sendMessage()" (keyup.enter)="sendMessage()" [disabled]="!settings.pegaIntegration" tabindex="0">
                    <i class="fa fa-envelope"></i>Send Message
                </button>

                <button mat-button (click)="logout()" (keyup.enter)="logout()" tabindex="0">
                    <i class="fa fa-sign-out"></i>Sign Out
                </button>
            </div>
            <div></div>
            <div class="uscb-layout-row">
                <div class="ecorr-user-initials circle-sm initials">
                    {{(portalUser | async)?.firstName.charAt(0) + (portalUser | async)?.lastName.charAt(0)}}</div>
                <div class="uscb-layout-column">
                    <div class="ecorr-username">{{(portalUser | async)?.firstName + ' ' + (portalUser | async)?.lastName}}
                    </div>
                    <div class="ecorr-email">{{(portalUser | async)?.email}}</div>
                </div>
            </div>
        </mat-toolbar-row>
    </section>

    <!-- Mobile user header -->
    <div class="uscb-layout-row nav-mobile uscb-hide-gt-md">
        <mat-toolbar-row class="user-header-sm uscb-layout-align-center-center uscb-flex-col-100">
            <div class="uscb-layout-row">
                <button [class.ecorr-text-navy]="(router.url === '/surveys')" mat-button [routerLink]="['surveys']"tabindex="0">
                    <div class="uscb-layout-column">
                        <i class="fa fa-tasks fa-lg"></i><br>
                    </div>
                    <div class="sm-label uscb-flex-row">Surveys</div>
                </button>
                <button [disabled]="false" [class.ecorr-text-navy]="(router.url === '/case-list')" mat-button [routerLink]="['/case-list']" tabindex="0">
                    <div class="uscb-layout-column">
                        <i class="fa fa-comments-o fa-lg"></i>
                        <span class="sm-label uscb-flex-row">Messages</span>
                    </div>
                </button>
                <button [class.ecorr-text-navy]="(router.url === '/manage-account')" (click)="showAccountSettings()" (keyup.enter)="showAccountSettings()" mat-button tabindex="0">
                    <div class="uscb-layout-column">
                        <i class="fa fa-cog fa-lg"></i>
                        <span class="sm-label uscb-flex-row">Account</span>
                    </div>
                </button>
                <button [class.ecorr-text-navy]="(router.url.includes('/submit-case'))" mat-button
                    *ngIf="(portalUser | async)?.showSendMessage && (portalUser | async)?.activeSurveyCards != null" [routerLink]="['/submit-case']"
                    [queryParams]="{surveyInstanceId: -102030405}" tabindex="0">
                    <div class="uscb-layout-column">
                        <i class="fa fa-envelope fa-lg"></i>
                        <span class="sm-label uscb-flex-row">Send Message</span>
                    </div>
                </button>
                <button (click)="logout()" (keyup.enter)="logout()" mat-button tabindex="0">
                    <div class="uscb-layout-column">
                        <i class="fa fa-sign-out fa-lg"></i>
                        <span class="sm-label uscb-flex-row">Sign Out</span>
                    </div>
                </button>
            </div>
        </mat-toolbar-row>
    </div>
</nav>
