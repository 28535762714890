import { Component, OnInit } from '@angular/core';
import { characterCount, accordion } from "uswds/src/js/components";
// import { Subscription } from "rxjs";
import { LanguageService } from '@/common/services/language.service';

@Component({
    selector: 'app-ecorr-usa-banner',
    templateUrl: './ecorr-usa-banner.component.html',
    styleUrls: ['./ecorr-usa-banner.component.scss']
})
export class EcorrUsaBannerComponent implements OnInit {

    ref: any;
    // language?: string = "es";
    // languageSub$: Subscription;
    lockImgHtml: string = `<span class="icon-lock"><img src="./assets/img/icon-lock.svg" style="height:1rem !important;"></span>`

    constructor ( private languageService: LanguageService ) { }

    ngOnInit() {
        // initialize
        characterCount.on(this.ref);
        accordion.on();

        // this.languageSub$ = this.languageService.language$.subscribe({
        //     next: (res) => {
        //       this.language = res.language;
        //     },
        //     error: (err) => {
        //       console.log(err)
        //     }
        // })
    }

    ngOnDestroy() {
        characterCount.off();
        accordion.off();

        // if (this.languageSub$) {
        //     this.languageSub$.unsubscribe();
        // }
    }

}