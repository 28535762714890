import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IdleTimeoutDialogData } from './idle-timeout-dialog-data';
import { IdleTimeoutDialogComponent } from './idle-timeout-dialog.component';

@Injectable()
export class IdleTimeoutDialogService {

  constructor(private dialog: MatDialog) { }

  matDialogRef: MatDialogRef<IdleTimeoutDialogComponent>;

  open(IdleTimeoutDialogData: IdleTimeoutDialogData) {
      const matDialogConfig = new MatDialogConfig();
      matDialogConfig.panelClass = 'no-padding-dialog-container';
      matDialogConfig.data = IdleTimeoutDialogData;
      matDialogConfig.disableClose = true;
      matDialogConfig.hasBackdrop = true;
      matDialogConfig.height = '210px';
      matDialogConfig.width = '450px';
      this.matDialogRef = this.dialog.open(IdleTimeoutDialogComponent, matDialogConfig);
  }

  continued(): Observable<any> {
      return this.matDialogRef.afterClosed().pipe(
          take(1),
          map(res => {
              return res;
          })
      );
  }

  close() {
    this.matDialogRef.close('[IdleTimeoutDialogService][close]  dialog is closed');
  }
}
